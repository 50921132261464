import { types } from "../types";
import Swal from "sweetalert2";

export const setError = (error) => {
  return {
    type: types.uiSetError,
    payload: error,
  };
};

export const removeError = () => {
  return {
    type: types.uiRemoveError,
  };
};

export const startLoading = () => {
  return {
    type: types.uiStartLoading,
  };
};

export const finishLoading = () => {
  return {
    type: types.uiFinishLoading,
  };
};

export const showModal = () => {
  return {
    type: types.uiShowModal,
  };
};

export const hideModal = () => {
  return {
    type: types.uiHideModal,
  };
};

export const showMessage = () => {
  return async () => {
    Swal.fire(
      "📢 Aviso Importante: ¡Bebé en Camino! 👶🎉",
      "Querida comunidad, el bebé Cucufate está a punto de llegar, por lo que algunos tiempos de envío podrían verse afectados. \n Para más información sobre los tiempos de entrega, por favor ponte en contacto con Ichi. \n ¡Gracias por tu comprensión y apoyo! 💖",
      "info"
    );
  };
};
