/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
//eslint-disable-next-line react-hooks/exhaustive-deps

import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import "font-awesome/css/font-awesome.min.css";

import ProductNotFound from "./ProductNotFound";
import Header from "../utils/Header";
import MetaHelmet from "../metahelmet";
import ProductModal from "../utils/ProductModal";

import { selectProduct, startGetSelectedProductByID, startGetSelectedProductBySlug } from "../../redux/actions/products";
import { DiscountOffer } from "../utils/ui/DiscountOffer";
import { Button } from "../utils/ui/Button";
import { showModal } from "../../redux/actions/ui";
import ProductGallery from "../utils/Gallery";

export default function Index() {
  const dispatch = useDispatch();
  const { selectedProduct } = useSelector((state) => state.products);

  const pathName = window.location.pathname;
  const slug = pathName.includes("/producto/") ? pathName.replace("/producto/", "") : "";
  const productUID = new URLSearchParams(window.location.search).get("uid") || "";

  const [notFound, setNotFound] = useState(false);
  const [checking, setChecking] = useState(true);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (productUID && productUID.length > 0) {
      dispatch(startGetSelectedProductByID(productUID));
    } else if (slug && slug.length > 0) {
      dispatch(startGetSelectedProductBySlug(slug));
    }
  }, []);

  useEffect(() => {
    if (selectedProduct !== null) {
      setMetadata({
        title: `Cucufate MX | ${selectedProduct.Nombre_del_Producto}`,
        description: selectedProduct.Descripcion_Corta,
        image: selectedProduct.Imagen_Principal,
        url: slug,
      });
    } else {
      setNotFound(true);
    }
    setChecking(false);
  }, [selectedProduct]);

  const handleAddToCart = () => {
    //dispatch(addItemToCart(product));
    dispatch(selectProduct(selectedProduct));
    // console.log('desde detalles', selectedProduct)
    dispatch(showModal());
  };

  if (checking) {
    //TO DO diseño visual de loader
    return <h1>Espere....</h1>;
  }

  return (
    <Fragment>
      {notFound ? (
        <ProductNotFound />
      ) : (
        <div className="catalogo">
          <MetaHelmet data={metadata}></MetaHelmet>
          <Header />
          <div className="container main light-font">
            <div className="row mt-4 px-0 mx-0">
              <nav className="col-12 col-md-8" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/">
                      <a>INICIO</a>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to="/shop">
                      <a>CATÁLOGO</a>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">{selectedProduct.Nombre_del_Producto}</li>
                </ol>
              </nav>
            </div>
            <div className="container main light-font">
              <div className="card overflow-hidden mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className={selectedProduct.Descuento_Oferta !== "-" ? "col-6" : "col-12"}>
                          <h1 className="border-bottom spacing-mb-12 spacing-pb-8">
                            <i className="fa fa-product-hunt"></i> {selectedProduct.Nombre_del_Producto}
                          </h1>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          {selectedProduct.Descuento_Oferta !== "-" ? (
                            <DiscountOffer descuento={selectedProduct.Descuento_Oferta} colorText="green" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="air__ecommerce__productMainImage mb-3">
                        {/*<ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Wristwatch by Ted Baker London",
                              isFluidWidth: true,
                              src: selectedProduct.Imagen_Principal,
                              srcSet: [].join(", "),
                              sizes: "(min-width: 480px) 30vw, 80vw",
                            },
                            largeImage: {
                              alt: "abc",
                              src: selectedProduct.Imagen_Principal,
                              width: 1313,
                              height: 1500,
                            },
                          }}
                        />*/}
                      </div>
                      <div className="d-flex flex-wrap justify-content-left mb-3">
                        {/*<div className="bookinnjen_productThumb width-100 height-100 border border-primary m-2">
                          <img className="img-fluid" src={selectedProduct.Imagen_Principal} alt="" />
                        </div>*/}

                        <ProductGallery productData={selectedProduct} />
                      </div>
                    </div>

                    <div className="col-lg-6 ">
                      <div className="bookinnjen_price font-size-24 font-weight-bold text-dark mb-2 text-right">
                        {selectedProduct.Precio_Oferta !== "-" ? (
                          <div className="d-flex justify-content-end flex-column">
                            <h2 className="heading-1 font-500 mb-0">${selectedProduct.Precio_Oferta}</h2>
                            <div className="d-flex justify-content-end align-items-baseline gap-2">
                              <del className="heading-3">${selectedProduct.Precio}</del>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h2 className="heading-1 font-500 mb-0">${selectedProduct.Precio}</h2>
                          </div>
                        )}
                      </div>
                      <div className="spacing-mb-8 border-bottom">
                        <b>DESCRIPCIÓN:</b>
                        <p>{selectedProduct.Descripcion_Corta}</p>
                      </div>

                      <div className="tab-content">
                        <div className="tab-pane active pt-3" id="tab-information-content" role="tabpanel" aria-labelledby="tab-information-content">
                          <b>CARACTERISTICAS:</b>
                          <ul>
                            <li>Marca: {selectedProduct.Marca}</li>
                            <li>Contenido: 1 pieza</li>
                            <li>Categoria: {selectedProduct.Categoria}</li>
                            {selectedProduct?.Tallas?.length > 0 && <li>Tallas: {selectedProduct.Tallas.join(" | ")}</li>}
                            <li>Caracteristica: {selectedProduct.Caracteristicas_Principales}</li>
                          </ul>
                          <p></p>
                        </div>
                        <div className="tab-pane active pt-3" id="tab-information-content2" role="tabpanel" aria-labelledby="tab-information-content">
                          <b>DESCRIPCIÓN LARGA:</b>
                          <p>{selectedProduct.Descripcion_Larga}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col 12">
                          <Button fullWidthMobile fullWidthTablet onClick={handleAddToCart}>
                            <span className="btn-addon">
                              <i className="btn-addon-icon fa fa-plus-circle"></i> &nbsp;
                            </span>
                            AGREGAR AL CARRITO
                          </Button>
                        </div>
                      </div>
                      <div id="previewimage" className="previewimagezoom"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductModal dontUnselect={true} />
        </div>
      )}
    </Fragment>
  );
}
