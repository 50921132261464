import { useEffect, useState } from "react";

const ProductGallery = ({ productData }) => {
  // Crear un array con todas las imágenes disponibles
  const images = [
    productData.Imagen_Principal,
    productData.Imagen_secundaria,
    productData.Imagen_secundaria_2,
    productData.Imagen_secundaria_3,
  ].filter((url) => url && url !== "-"); // Filtra para evitar valores `undefined` o `null`

  const [mainImage, setMainImage] = useState(images[0]); // Imagen principal por defecto
  useEffect(() => {
    if (images.length > 0 && !mainImage) {
      setMainImage(images[0]); // Establecer la imagen principal por defecto al cargar el componente
    }
  }, [images]);

  return (
    <div>
      {/* Imagen Principal */}
      <div className="air__ecommerce__productMainImage mb-3">
        <img className="img-fluid" src={mainImage} alt="Product" />
      </div>

      {/* Miniaturas */}
      <div className="d-flex gap-2">
        {images.map((image, index) => (
          <img
            key={index}
            className={`img-thumbnail ${mainImage === image ? "border-primary" : ""}`}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => setMainImage(image)}
            style={{ cursor: "pointer", width: "80px", height: "80px" }}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductGallery;
